<template>
  <div class="home-box">
    <!-- 顶部菜单栏 v-if="isMenuShow" -->
    <div :key="domKey" v-if="isMenuShow">
      <el-menu
        :default-active="this.$route.path"
        :router="true"
        class="home-menu"
        mode="vertical"
        @select="handleSelect"
        background-color="#000000"
        text-color="#fff"
        active-text-color="#09CE99 "
        style="position: fixed; background-color: rgb(0, 0, 0) !important"
        ><span class="home-logo-box"></span>
        <!-- <span class="home-logo-box">睿羿科技(长沙)有限公司</span> -->
        <el-menu-item
          index="/contact"
          class="home-menu-item"
          style="margin-right: 200px !important"
          >加入我们</el-menu-item
        >
        <el-menu-item index="/project" class="home-menu-item"
          >项目案列</el-menu-item
        >
        <el-menu-item index="/product" class="home-menu-item"
          >主要产品</el-menu-item
        >
        <el-menu-item index="/achievement" class="home-menu-item"
          >公司成果</el-menu-item
        >
        <el-menu-item index="/core" class="home-menu-item"
          >核心技术</el-menu-item
        >
        <el-menu-item index="/info" class="home-menu-item"
          >公司简介</el-menu-item
        >
        <el-menu-item index="/realHome" class="home-menu-item"
          >首页</el-menu-item
        >
      </el-menu>
    </div>

    <!-- 路由跳转 -->
    <router-view />
    <div class="home-bottom">
      <div class="bottom-center-box">
        <div>
          <div class="bottom-logo"></div>
          <div class="bottom-tel">联系电话：0731-85661216</div>
          <div class="bottom-address">
            地址一：山东省淄博市临淄区金岭回族镇纬三路168号203室
          </div>
          <div class="bottom-address1">
            地址二：长沙市雨花区东塘街道芙蓉中路三段589号芙蓉路小学综合楼1110-1112号
          </div>
          <div class="bottom-menu">
            <span class="bottom-menu-item" @click="pushInfo">公司简介</span>
            <span class="bottom-menu-item" @click="pushCore">核心技术</span>
            <span class="bottom-menu-item" @click="pushachievement"
              >公司成果</span
            >
            <span class="bottom-menu-item" @click="pushproduct">主要产品</span>

            <span class="bottom-menu-item" @click="pushProject">项目案列</span>
            <span class="bottom-menu-item" @click="pushContact" style=""
              >加入我们</span
            >
          </div>
          <div class="Official-Accounts-Pic"></div>
          <div class="wechat-Official-Accounts">公众号</div>
          <div class="bottom-wechat"></div>
          <div class="wechat-name">商务合作</div>
          <el-divider class="bottom-divider"></el-divider>
          <div class="bottom-name">
            <span>睿羿科技（长沙）有限公司</span>
          </div>
          <div class="bottom-name1">
            <span>睿羿科技（山东）有限公司</span>
          </div>
          <div class="bottom-ga_number">
            <span @click="pushICP">湘ICP备2022015965号-1</span>
            <div>
              <img src="./images/备案图标.png" class="bottom-ga_number-img" />
              <el-link
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010502001482"
                :underline="false"
                style="color: #fff"
                class="bottom-ga-ba"
                target="_blank"
                ><span class="bottom-ga-ba-span"
                  >湘公网安备 43010502001482号</span
                ></el-link
              >
            </div>
          </div>
          <div class="bottom-ga_number1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      domKey: 1,
      url: 'https://beian.miit.gov.cn',
      currentMenu: '',
      currentColor: '#6ad9e4',
      activeIndex: '1', //默认激活的页面
      isIndexShow: true, //用于控制首页的显示和隐藏
      isMenuShow: true,
      isBottomShow: false,
      screenheight: document.documentElement.clientHeight + 'px',
    };
  },
  // created() {
  //   if (this.$route.name !== 'home') {
  //     this.isBottomShow = true;
  //   }
  // },
  mounted() {
    this.init();
    window.addEventListener('scroll', this.showMenuFn);
    window.onresize = () => {
      return () => {
        this.screenheight = document.documentElement.clientHeight + 'px';
      };
    };
    console.log('路由名称', this.$route.name);
    console.log('路由路径', this.$route.path);
    if (this.$route.path !== '/home') {
      this.isIndexShow = false;
      this.isBottomShow = true;
    }
  },
  // // 检测路由变化
  watch: {
    // $route(to, from) {
    //   window.location.reload(); //监测到路由发生跳转时刷新一次页面
    // },
    $route(e) {
      this.currentMenu = e.path; // e里面的是当前路由的信息
      // const first = sessionStorage.setItem(this.currentMenu);
      console.log(this.currentMenu);
      if (e.name) {
        this.domKey += 1;
      }
      console.log(111);
    },
  },
  methods: {
    init() {
      this.domKey += 1;
      console.log(this.domKey);
    },
    pushICP() {
      // window.location.href = this.url;
      window.open(this.url);
    },
    // el-menu点击菜单项触发的函数
    handleSelect(key, keyPath) {
      console.log('key', key, keyPath);
      // switch (keyPath) {
      //   case '/realHome':
      //     this.isIndexShow = true;
      //     this.isBottomShow = false;
      //     if (!this.$route.name.includes('realHome')) {
      //       //当前路径中不包括本path就执行
      //       this.$router.push({ name: 'realHome' });
      //     }
      //     break;
      //   case '/info':
      //     this.isIndexShow = false;
      //     this.isBottomShow = true;
      //     //当前路径中不包括本path就执行
      //     if (!this.$route.name.includes('info')) {
      //       this.$router.push({ name: 'info' });
      //     }
      //     break;
      //   case '/core':
      //     console.log('核心技术', key);
      //     this.isIndexShow = false;
      //     this.isBottomShow = true;
      //     //当前路径中不包括本path就执行
      //     if (!this.$route.name.includes('core')) {
      //       this.$router.push({ name: 'core' });
      //     }
      //     break;
      //   case '/realHome':
      //     this.isIndexShow = true;
      //     this.isBottomShow = false;
      //     if (!this.$route.name.includes('realHome')) {
      //       //当前路径中不包括本path就执行
      //       this.$router.push({ name: 'realHome' });
      //     }
      //     break;
      // }
      // if (keyPath === '/realHome') {
      //   console.log('首页', key);
      //   this.isIndexShow = true;
      //   this.isBottomShow = false;
      //   if (!this.$route.name.includes('realHome')) {
      //     //当前路径中不包括本path就执行
      //     this.$router.push({ name: 'realHome' });
      //   }
      //   // this.$router.push({ name: '/' });
      // } else if (keyPath === '/info') {
      //   console.log('公司简介', key);
      //   this.isIndexShow = false;
      //   this.isBottomShow = true;

      //   //当前路径中不包括本path就执行
      //   if (!this.$route.name.includes('info')) {
      //     this.$router.push({ name: 'info' });
      //   }
      // } else if (keyPath === '/core') {
      //   console.log('核心技术', key);
      //   this.isIndexShow = false;
      //   this.isBottomShow = true;

      //   //当前路径中不包括本path就执行
      //   if (!this.$route.name.includes('core')) {
      //     this.$router.push({ name: 'core' });
      //   }
      // } else if (keyPath === '/product') {
      //   console.log('主要产品', key);
      //   this.isIndexShow = false;
      //   this.isBottomShow = true;

      //   //当前路径中不包括本path就执行
      //   if (!this.$route.name.includes('product')) {
      //     this.$router.push({ name: 'product' });
      //   }
      // } else if (keyPath === '/contact') {
      //   console.log('联系我们', key);
      //   this.isIndexShow = false;
      //   this.isBottomShow = true;

      //   //当前路径中不包括本path就执行
      //   if (!this.$route.name.includes('contact')) {
      //     this.$router.push({ name: 'contact' });
      //   }
      // }
    },
    // 底部menu文字点击触发路由跳转
    pushInfo() {
      this.$router.push({ name: 'info' });
      this.isIndexShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    pushCore() {
      this.$router.push({ name: 'core' });
      this.isIndexShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    pushproduct() {
      this.$router.push({ name: 'product' });
      this.isIndexShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    pushContact() {
      this.$router.push({ name: 'contact' });
      this.isIndexShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    pushachievement() {
      this.$router.push({ name: 'achievement' });
      this.isIndexShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    pushProject() {
      this.$router.push({ name: 'project' });
      this.isIndexShow = false;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    showMenuFn() {
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 60
      ) {
        this.isMenuShow = false;
      } else {
        this.isMenuShow = true;
      }
    },
  },
};
</script>

<style lang="scss">
// 公共样式
// .home-carousel-box {
//   position: relative;
//   width: 100%;
//   height: 100vh;
// }
.home-box {
  // max-width: 1244px;
  // padding: 0 0 0 50px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  // background-color: #ccc;
  // position: relative;
}
.home-menu {
  // top: 0;
  // position: sticky !important;
  // position: absolute;
  position: fixed;
  z-index: 9999;
  width: 100%;
  margin-top: 0px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  align-items: center;
  padding-right: 200px;
  padding-left: 30px;
  background-color: transparent !important;
  overflow: hidden;
  .home-logo-box {
    float: left;
    font-size: 20px;
    color: #fff;
    background: url('../assets/images/home-img/logo3.png') center no-repeat;
    background-size: 100% 100%;
    margin-left: 200px;
    margin-top: 15px;
    width: 110px;
    height: 30px;
  }
  .el-menu-item {
    width: 120px;
    float: right;
    font-size: 20px;
    // background: rgb(0, 0, 0, 0);
  }
  .home-menu-item {
    background-color: none !important;
    background: rgb(0, 0, 0, 0);
    // width: 150px;
  }
  .el-menu-item:hover,
  .el-menu-item:focus {
    background-color: transparent !important;
    color: #09ce99 !important;
  }
}

.home-bottom {
  width: 100%;
  height: 290px;
  background: #1e2022;
  padding-top: 30px;
  font-size: 20px;
  // margin-top: 50px;
  color: #fff;
  .bottom-center-box {
    width: 1680px;
    min-width: 700px;
    height: 230px;
    margin: 0px auto;
    // background-color: orange;
    position: relative;
    .bottom-logo {
      display: inline-block;
      position: absolute;
      width: 110px;
      height: 30px;
      background: url('../assets/images/home-img/logo1.png') center no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      left: 20px;
      top: 0px;
    }
    .bottom-tel {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 30px;
      // cursor: pointer;
      padding-top: 20px;
      left: 20px;
      top: 40px;
    }
    .bottom-address {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 30px;
      // cursor: pointer;
      padding-top: 40px;
      left: 20px;
      top: 60px;
    }
    .bottom-address1 {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 30px;
      // cursor: pointer;
      padding-top: 40px;
      left: 20px;
      top: 100px;
    }
    .bottom-divider {
      position: absolute;
      opacity: 0.2;
      top: 165px;
      left: 0px;
    }
    .bottom-wechat {
      display: inline-block;
      position: absolute;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=zm.png')
        center no-repeat;

      background-size: 100% 100%;
      width: 100px;
      height: 100px;
      right: 20px;
      top: 40px;
    }
    .wechat-name {
      display: inline-block;
      position: absolute;
      text-align: center;
      width: 100px;
      height: 100px;
      right: 20px;
      top: 150px;
    }
    // 公众号
    .Official-Accounts-Pic {
      display: inline-block;
      position: absolute;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=gzh.png')
        center no-repeat;
      background-size: 100% 100%;
      width: 100px;
      height: 100px;
      right: 150px;
      top: 40px;
    }
    .wechat-Official-Accounts {
      display: inline-block;
      position: absolute;
      text-align: center;
      width: 100px;
      height: 100px;
      right: 150px;
      top: 150px;
    }

    .bottom-menu {
      display: inline-block;
      position: absolute;
      right: 20px;
      top: 0px;
      .bottom-menu-item {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;
      }
      .bottom-menu-item:hover {
        color: #09ce99;
      }
    }
    .bottom-name {
      display: inline-block;
      position: absolute;
      bottom: -30px;
      left: 20px;
    }
    .bottom-name1 {
      display: inline-block;
      position: absolute;
      bottom: -30px;
      left: 300px;
    }
    .img-bottom {
      display: inline-block;
      cursor: pointer;
      position: absolute;
      // right: 20px;
      height: 25px;
      width: 25px;
      bottom: -30px;
      right: 310px;
    }
    .bottom-number {
      // display: flex;
      display: inline-block;
      line-height: 30px;
      cursor: pointer;
      position: absolute;
      bottom: -30px;
      right: 20px;
      font-size: 20px;
      color: #fff;
    }
    .bottom-ga_number {
      display: flex;
      cursor: pointer;
      position: absolute;
      // right: 20px;
      bottom: -30px;
      right: 10px;
      .bottom-ga_number-img {
        float: left;
        height: 28px;
        width: 28px;
        margin-right: 5px;
        margin-left: 50px;
      }
      .bottom-ga-ba {
        height: 28px;
        float: left;
        font-size: 20px;
        color: #fff;
        .bottom-ga-ba-span {
          text-align: center;
          line-height: 30px;
          font-size: 20px !important;
        }
      }
    }
    .bottom-ga_number1 {
      // display: inline-block;
      cursor: pointer;
      position: absolute;

      width: 350px;
      // right: 20px;
      margin-right: 5px;
      bottom: -30px;
      right: 10px;
    }
  }
}
</style>
