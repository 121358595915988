<template>
  <div>
    <!-- 顶部首页内容-->
    <div style="background: #f7f8f9">
      <!-- <h1>首页</h1> -->
      <div class="home-carousel-box">
        <el-carousel
          height="600px"
          :interval="4000"
          class="home-carousel"
          autoplay
        >
          <el-carousel-item v-for="(item, index) in fileList" :key="index">
            <div class="el-carousel-title" v-html="item.title1"></div>
            <img
              :src="item.src"
              alt="图片丢失了"
              class="home-top-img"
              width="100%"
              height="100%"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="home-container">
        <div class="home-box2">
          <div class="box2-info-box">
            <div class="box2-text">
              <div class="box2-title">睿羿科技</div>
              <div class="tile-bg"></div>
              <p style="margin-top: 13px">
                睿羿科技团队致力于打造中国领先的自动驾驶与智能装备硬科技公司，用人工智能和自动驾驶技术赋能传统制造业以及智慧城市的发展，为全行业客户提供安全可靠、超高性价比的全场景智能驾驶产品及服务。
              </p>
              <el-button round class="home-check-more" @click="pushInfo"
                >查看更多<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
              <!-- el-icon-arrow-right -->
            </div>
          </div>

          <div class="box2-container">
            <div class="box3-img" style="border-radius: 15px">
              <div v-if="this.imgshow">
                <img
                  id="playvd"
                  style=""
                  alt=""
                  src="https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2睿羿科技.jpg"
                  class="playv"
                />
                <span class="el-icon-bofang1" @click="openVideo"
                  ><i class="el-icon-caret-right"></i
                ></span>
                <!-- <i class="el-icon-video-play" @click="openVideo"></i>  -->
                <!--  width: 967px;
        height: 552px; -->
              </div>
              <div class="videobox" id="videobox">
                <video
                  class="videoWH"
                  controls="controls"
                  id="video"
                  ref="videoPlay"
                >
                  <source
                    src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=gsgm.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- background-color: #eaeef1; -->
      <div class="home-container" style="margin: 17px 0 17px 0">
        <div class="home-box2">
          <div class="box2-container">
            <div class="box2-img-box" id="box2-img2"></div>
          </div>
          <div class="box2-info-box">
            <div class="box2-text">
              <div class="box2-title">公司产品</div>
              <div class="tile-bg"></div>
              <p style="margin-top: 13px">
                已研发出无人环卫车、无人物流车、无人接驳车、无人售卖车、无人巡防车等无人智能装备产品。
              </p>
              <el-button round class="home-check-more" @click="pushproduct"
                >查看更多<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
              <!-- el-icon-arrow-right -->
            </div>
          </div>
        </div>
      </div>
      <div class="home-container">
        <div class="home-box2">
          <div class="box2-info-box">
            <div class="box2-text">
              <div class="box2-title">项目运行</div>
              <div class="tile-bg"></div>
              <p style="margin-top: 13px">
                从2022年3月开始，睿羿科技组建运营团队开展了无人车的部署和测试运行，目前已在济南、淄博、长沙、重庆、宜宾、呼和浩特等全国多个地区开展常态化示范运营。
              </p>
              <el-button round class="home-check-more" @click="pushproject"
                >查看更多<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </div>
          </div>

          <div class="box2-container">
            <div class="box2-img-box" id="box2-img3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'realHome',
  data() {
    return {
      imgshow: true,
      // carouselVh: 100 + '%', //首页轮播图高度
      fileList: [
        {
          title1: '让科技有为，创智驾未来',
          title2: '创智驾未来',
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2banner1创驾驶未来.jpg',
        },
        {
          title1: '无人/智能装备及全栈式<br>解决方案供应商',
          title2: '',
          id: 2,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2banner3解决方案供应商.jpg',
        },
        {
          title1: '致力于突破性的AI科技<br>和自动驾驶技术',
          title2: '',

          id: 3,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2banner2自动驾驶技术.jpg',
        },
      ],
    };
  },
  methods: {
    openVideo() {
      this.imgshow = false;
      this.$refs['videoPlay'].play();
    },
    pushInfo() {
      this.$router.push('/info');
    },
    pushproduct() {
      this.$router.push('/product');
    },
    pushproject() {
      this.$router.push('/project');
    },
  },
};
</script>

<style lang="scss" scoped>
// .home-carousel-box {
//   height: 337px;
// }
.el-carousel-title {
  display: inline-block;
  text-align: center;
  position: absolute;
  // color: #fff;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // margin: 250px auto;
  // color: #475669;
  font-weight: 400;
  font-size: 60px;
  color: #ffffff;
  width: 100%;
  // font-weight: 700;
  // opacity: 0.75;
  // width: 70%;
}
.home-top-img {
}

.home-carousel {
  width: 100%;
  text-align: center;
  align-items: center;
  position: relative;

  .el-carousel-text {
    display: inline-block;
    position: absolute;
    color: #fff;
    z-index: 100;
    left: 30%;
    bottom: 5vh;
    transform: translate(-50%, -50%);
    font-size: 14px;
    opacity: 0.75;
  }
  .bottom-number_home {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    color: #fff;
    z-index: 100;
    left: 50%;
    bottom: 5vh;
    transform: translate(-50%, -50%);
    font-size: 14px;
    opacity: 0.75;
  }
  .bottom-ga-number_home {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    color: #fff;
    z-index: 100;
    left: 70%;
    bottom: 4vh;
    transform: translate(-50%, -50%);
    font-size: 14px;
    opacity: 0.75;
  }
  .home-carousel-img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: cover !important;
  }
  .home-carousel-img-div {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.7);
    z-index: 99;
  }
  .el-carousel__arrow {
    width: 80px !important;
    height: 80px !important;
  }
}
.home-container {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 814px;
  align-items: center;
  .home-box2 {
    width: 1600px;
    height: 552px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // overflow: hidden;
    // padding-bottom: 10px;
    .home-check-more {
      width: 134px;
      height: 46px;
      margin-top: 30px;
      margin-bottom: 23px;
      color: #434343;
      border-radius: 77px;
      border: 1px solid #434343;
    }
    .box2-container {
      width: 967px;
      height: 552px;
      // margin: 20px auto;
      // margin: 0 auto;
      // padding-top: 9px;
      .box2-img-box {
        width: 967px;
        height: 552px;
        // background-color: aquamarine;
      }
      figure {
        width: 967px;
        height: 552px;
        padding: 0;
        background: #fff;
        overflow: hidden;
        position: relative;
      }
      figure .el-icon-video-play {
        left: 100px;
        top: 50px;
        font-size: 50px;
        position: absolute;
        cursor: pointer;
      }
      .box3-img .playv {
        width: 967px;
        height: 552px;
        -webkit-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
      }
      .box3-img .videobox .videoWH {
        width: 967px;
        height: 552px;
      }
      .box3-img:hover .playv {
        width: 967px;
      }
      .box3-img:hover span {
        color: #ffff;
        opacity: 0.7;
      }
      .playv {
        width: 967px;
        height: 552px;
        // background-color: chartreuse;
        // background: url('@/assets/images/info-img/位图(1).png') center no-repeat;
        background-size: 100% 100%;
      }
      .box3-img .el-icon-bofang1 {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        color: #fff;
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        text-align: center;
        opacity: 0.5;
        line-height: 100px;
        font-size: 60px;
        position: absolute;
        background: #000000;
        .el-icon-caret-right {
          opacity: 1;
        }
      }

      .box3-img {
        width: 967px;
        height: 552px;
        // background-color: chartreuse;
        // margin: 20px auto;
        // background: url('@/assets/images/info-img/位图(1).png') center no-repeat;
        background-size: 100% 100%;
        padding: 0;
        // background: #fff;
        overflow: hidden;
        position: relative;
      }
    }
    .box2-info-box {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      height: 552px;
      width: 520px;
      background: url('../images/文字底色2x.png') center no-repeat;
      background-size: 530px 532px;
      .box2-title {
        // width: 520px;
        // margin: 20px auto;
        // height: 39px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 45px;
        color: #262626;
        line-height: 70px;
        text-align: left;
        font-style: normal;
      }
      .tile-bg {
        // width: 520px;
        // margin: 20px auto;
        margin-top: 12px;
        margin-bottom: 25px;
        // width: 28px;
        width: 50px;
        height: 6px;
        background: #09ce99;
        border-radius: 4px;
      }
      .box2-text {
        // margin: 20px auto;
        // margin-top: 17px;
        // width: 520px;
        // height: 100%;
        // height: 250px;
        // margin-top: 13px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        line-height: 50px;
        text-align: justify;
        font-style: normal;
      }
    }
    #box2-img1 {
      background: url('@/assets/images/home-img/banner-1.png') center no-repeat;
      background-size: 100% 100%;
    }
    #box2-img2 {
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司产品.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    #box2-img3 {
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2项目运行.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
