<template>
  <div class="info-container" style="background: #f7f8f9">
    <div class="info-box1">
      <div class="info-title">公司成果</div>
      <div class="info-text">
        <!-- <p class="info-p">
          用人工智能和自动驾驶技术赋能传统制造业以及智慧城市的发展，已研发出无人环卫车、无人物流车、无人接驳车、无人售卖车、无人巡防车等无人智能装备产品。
        </p> -->
      </div>
    </div>
    <!-- <div class="info-box2" style="height: 400px; background: #ffffff">
      <div class="box2-title">公司成果</div>
      <div class="tile-bg"></div>
      <div class="box2-text">
        <p class="info-p">
          睿羿科技以可编程线控底盘技术及自动驾驶核心算法为基础，自主研发出具有毫秒级响应的线控底盘和域控制器设备，目前具有核心自主知识产权30余项。睿羿科技掌握了百万平米级大范围高精度地图建模技术、多源异构信息融合的高精度定位技术、车辆实时动态路径规划技术及转向/速度紧耦合的高精度轨迹跟踪控制技术等多项核心关键技术，生产了无人环卫、无人接驳、无人零售、无人安防、无人物流等多款功能型无人车产品，在无人驾驶车辆装备领域内的出货量和市场占有率居行业前列。
        </p>
      </div>
    </div> -->
    <div class="info-box2">
      <div class="box2-title">高新技术企业/知识产权</div>
      <div class="tile-bg"></div>
      <div class="box2-text">
        <p class="info-p">
          2023年12月睿羿科技（山东）有限公司/睿羿科技（长沙）有限公司均获得高新技术企业荣誉资质。截止至目前，公司已累计申请各类知识产权50余项，其中，已授权发明专利
          7 项、实用新型专利 2 项、软件著作权 28 项。
        </p>
        <el-carousel height="828px" class="info-carousel" style="">
          <el-carousel-item
            v-for="(item, index) in achievementList"
            :key="index"
          >
            <img
              v-lazy="item.src"
              style="
                object-fit: contain;
                height: 720px;
                width: 100%;
                margin: 40px auto !important;
              "
            />
            <!-- <el-image :src="item.src" ></el-image> -->
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- <div class="box2-img"></div> -->
    </div>
    <div class="info-box2" style="background: #ffffff; margin-bottom: 150px">
      <div class="box2-title">通过自动驾驶系统权威测试</div>
      <div class="tile-bg"></div>
      <div class="box2-text">
        <p class="info-p">
          2023年8月，睿羿科技无人清扫车于重庆车辆检测研究院通过了功能型无人车自动驾驶功能场地测试标准，完成了车辆交通标志和标线识别及响应、交通信号灯识别及响应、障碍物识别及响应等38个测试项目，是国内少数几家通过该行业测试标准的企业之一。
        </p>
      </div>
      <div class="box2-container">
        <div class="box3-img" style="margin: 100px auto">
          <div>
            <img
              id="playvd"
              v-if="imgshow"
              alt=""
              src="https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2通过自动驾驶系统权威测试.jpg"
              class="playv"
            />
            <span class="el-icon-bofang1" v-if="imgshow" @click="openVideo"
              ><i class="el-icon-caret-right"></i
            ></span>
            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
          <div class="videobox" id="videobox">
            <video
              class="videoWH"
              controls="controls"
              id="video"
              ref="videoPlay"
            >
              <source
                src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=gscg-CQ.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'info',
  data() {
    return {
      imgshow: true,
      achievementList: [
        {
          id: 4,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权1.jpg',
        },
        {
          id: 6,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权2.jpg',
        },
        {
          id: 1,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权3.jpg',
        },
        {
          id: 2,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权4.jpg',
        },
        {
          id: 3,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权5.jpg',
        },
        {
          id: 5,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权6.jpg',
        },

        {
          id: 7,
          src: 'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2高新技术企业_知识产权7.jpg',
        },
      ],
    };
  },
  methods: {
    openVideo() {
      this.imgshow = false;
      this.$refs['videoPlay'].play();
    },
  },
};
</script>

<style scoped>
/deep/ .el-carousel__arrow--right {
  right: 80px !important;
}
/deep/ .el-carousel__arrow--left {
  left: 80px !important;
}
/deep/.el-carousel__arrow {
  width: 50px;
  height: 50px;
}
/deep/.el-icon-arrow-right:before {
  font-size: 25px;
}
/deep/.el-icon-arrow-left:before {
  font-size: 25px;
}
</style>

<style lang="scss" scoped>
.info-box1 {
  width: 100%;
  min-width: 880px;
  height: 600px;
  min-height: 500px;
  margin-top: 0px !important;
  // background-color: #545c64;
  background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司成果背景.jpg')
    center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  overflow: hidden;
  color: #fff;
  .info-title {
    margin: 0px auto;
    margin-top: 244px;
    // margin-bottom: 5vh;
    font-size: 80px;
    // font-weight: 700;
    text-align: center;
  }
  .info-text {
    width: 700px;
    height: 200px;
    // background-color: orange;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
  }
}
.info-box2 {
  width: 100%;
  height: 100%;
  min-width: 880px;
  //   min-height: 500px;
  // background-color: #fff;
  // background-color: aqua;
  overflow: hidden;
  padding: 100px 0px 80px 0px;
  // color: #fff;
  .box2-title {
    margin: 10px auto;
    // text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .box2-text {
    width: 100%;
    margin: 25px auto;
  }
  .box2-img {
    width: 700px;
    height: 400px;
    // background-color: chartreuse;
    margin: 20px auto;
    background: url('../../assets/images/info-img/位图.png') center no-repeat;
    background-size: 100% 100%;
  }
}
.info-p {
  margin: 25px auto;
  width: 1540px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  line-height: 50px;
  text-align: left;
  font-style: normal;
}
.info-carousel {
  margin-top: 50px;
  width: 1680px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
}
.box2-container {
  width: 1680px;
  height: 875px;
  background: #f8f8f8;
  border-radius: 20px;
  margin: 0 auto;
  padding-top: 9px;
  .box2-img-box {
    width: 100%;
    height: 675px;
    // background-color: aquamarine;
  }
  //   figure {
  //     width: 700px;
  //     height: 400px;
  //     padding: 0;
  //     background: #fff;
  //     overflow: hidden;
  //     position: relative;
  //   }
  //   figure .el-icon-bofang1 {
  //     left: 120px;
  //     top: 70px;
  //     font-size: 50px;
  //     position: absolute;
  //     cursor: pointer;
  //   }
  .box3-img .playv {
    width: 1200px;
    height: 675px;
    // margin: 100px auto;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .box3-img .videobox .videoWH {
    width: 1200px;
    height: 675px;
  }
  .box3-img:hover .playv {
    width: 1200px;
  }
  .box3-img:hover span {
    color: #ffff;
    opacity: 0.7;
  }
  .playv {
    width: 1200px;
    height: 675px;
    // background-color: chartreuse;
    // background: url('../../assets/images/info-img/位图(1).png') center no-repeat;
    background-size: 100% 100%;
  }
  .box3-img {
    width: 1200px;
    height: 675px;
    // background-color: chartreuse;
    margin: 50px auto;
    // background: url('../../assets/images/info-img/位图(1).png') center no-repeat;
    background-size: 100% 100%;
    padding: 0;
    // background: #fff;
    overflow: hidden;
    position: relative;
    .el-icon-bofang1 {
      left: 550px;
      top: 301px;
      position: absolute;
      color: #fff;
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 100px;
      text-align: center;
      opacity: 0.5;
      line-height: 100px;
      font-size: 60px;
      position: absolute;
      background: #000000;
      .el-icon-caret-right {
        opacity: 1;
      }
    }
  }
}
.tile-bg {
  margin: 12px auto;
  width: 50px;
  height: 6px;
  background: #09ce99;
  border-radius: 4px;
}
</style>