<template>
  <div
    class="contact-container"
    style="background: #f7f8f9; padding-bottom: 130px"
  >
    <div class="contact-box1">
      <div class="contact-title">加入我们</div>
    </div>
    <div class="contact-box2">
      <div class="box2-center">
        <div class="box2-title">价值理念</div>
        <div class="tile-bg"></div>
        <div class="box2-text">
          <p>
            睿羿科技团队致力于打造中国领先的自动驾驶与智能装备硬科技公司，用人工智能和自动驾驶技术赋能传统制造业以及智慧城市的发展，为全行业客户提供安全可靠、超高性价比的全场景智能驾驶产品及服务。
          </p>
        </div>
        <div class="box2-bottom">
          <el-row>
            <el-col :span="6">
              <div class="box2-bottom-div">
                <div class="box2-bottom-imgs" id="box2-bottom-img1"></div>
                <div class="box2-bottom-text">责任担当</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="box2-bottom-div">
                <div class="box2-bottom-imgs" id="box2-bottom-img2"></div>
                <div class="box2-bottom-text">开放合作</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="box2-bottom-div">
                <div class="box2-bottom-imgs" id="box2-bottom-img3"></div>
                <div class="box2-bottom-text">卓越创新</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="box2-bottom-div">
                <div class="box2-bottom-imgs" id="box2-bottom-img4"></div>
                <div class="box2-bottom-text">包容团结</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="contact-box3">
      <div class="box3-title" style="margin-top: 89px">热招岗位</div>
      <div class="tile-bg"></div>
      <div class="box3-center">
        <div class="box3-div">
          <div class="box3-div-text1">山东</div>
          <div class="box3-div-text2">
            <el-button
              round
              class="home-check-more"
              @click="pushShanDong"
              style="background-color: transparent; color: #ffffff"
              >查看更多<i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </div>
        </div>
        <div class="box4-div" style="margin-left: 66px">
          <div class="box3-div-text1">长沙</div>
          <div class="box3-div-text2">
            <el-button
              round
              class="home-check-more"
              @click="pushChangSha"
              style="background-color: transparent; color: #ffffff"
              >查看更多<i class="el-icon-arrow-right el-icon--right"></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系电话：0731-85661216
邮箱：hr@realepocs.com -->
    <div class="contact-box4">
      <div class="box3-title">联系方式</div>
      <div class="tile-bg"></div>
      <div class="box3-center">
        <div
          class="box3-div"
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img src="../../assets/images/contact-img/电话.png" />

            <span
              style="
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 24px;
                color: #333333;
                line-height: 33px;
                text-align: center;
                font-style: normal;
                margin-left: 50px;
              "
            >
              联系电话：0731-85661216</span
            >
          </div>

          <!-- <el-row>
            <el-col></el-col>
          </el-row> -->
        </div>
        <div
          class="box3-div"
          style="
            margin-left: 66px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img src="../../assets/images/contact-img/邮箱.png" />

            <span
              style="
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 24px;
                color: #333333;
                line-height: 33px;
                text-align: center;
                font-style: normal;
                margin-left: 50px;
              "
            >
              邮箱：hr@realepocs.com</span
            >
          </div>
        </div>
      </div>
      <!-- <div class="box3-content"><span> 联系电话：0731-85661216</span></div>
      <div class="box3-content"><span>邮箱：hr@realepocs.com</span></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urL1: 'https://www.zhipin.com/gongsir/094e06925d3662821XR_39y9EFI~.html?ka=position-1',
      urL2: 'https://www.zhipin.com/gongsir/0b3fe1e8c28ff16d1XR62Nq4ElM~.html?ka=position-1',
    };
  },
  mounted() {
    if (this.$router.name === '/') {
      this.isContact = false;
    }
  },
  methods: {
    pushShanDong() {
      // window.location.href = this.url;
      window.open(this.urL1);
    },
    pushChangSha() {
      // window.location.href = this.url;
      window.open(this.urL2);
    },
  },
};
</script>

<style lang="scss" scoped>
// @import url('@/assets/images/css/big.less');
// @media only screen and (max-width: 750px) {
//   @import url('@/assets/images/css/contact.less');
// }

// @media only screen and (min-width: 750px) {
//   @import url('@/assets/images/css/big.less');
// }
.home-check-more {
  width: 134px;
  height: 46px;
  border-radius: 77px !important;
  border: 1px solid #ffffff;
}

.contact-box1 {
  width: 100%;
  min-width: 480px;
  height: 600px;

  min-height: 500px;
  margin-top: 0px !important;
  // background-color: #545c64;
  background: url('https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2加入我们.jpg')
    no-repeat center;
  background-size: cover;
  background-blend-mode: multiply;
  overflow: hidden;
  color: #fff;
  .contact-title {
    margin: 0px auto;
    margin-top: 244px;
    // margin-bottom: 5vh;
    font-size: 80px;
    // font-weight: 700;
    text-align: center;
  }
  .contact-text {
    width: 700px;
    height: 200px;
    // background-color: orange;
    font-size: 16x;
    line-height: 28px;
    margin: 0px auto;
    text-align: center;
  }
}
// @media screen and (min-width: 750px) {
//   .contact-box1 {
//     width: 100%;
//     min-width: 880px;
//     height: 100vh;
//     min-height: 200px;
//     margin-top: 0px !important;
//     // background-color: #545c64;
//     background: url('@/assets/images/contact-img/加入我们.png')
//       rgba(2, 19, 36, 0.7) no-repeat center fixed;
//     background-size: cover;
//     background-blend-mode: multiply;
//     overflow: hidden;
//     color: #fff;
//     .contact-title {
//       margin: 0px auto;
//       margin-top: 30vh;
//       margin-bottom: 5vh;
//       font-size: 80px;
//       // font-weight: 700;
//       text-align: center;
//     }
//     .contact-text {
//       width: 700px;
//       height: 200px;
//       // background-color: orange;
//       font-size: 16x;
//       line-height: 28px;
//       margin: 0px auto;
//       text-align: center;
//     }
//   }
// }
.contact-box2 {
  width: 100%;
  min-width: 880px;
  // min-height: 500px;
  // background-color: aqua;
  // background-color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: auto;
  color: #fff;
  .box2-title {
    // font-size: 40px;
    margin: 0px auto;
    // text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .box2-text {
    margin: 25px auto;
    width: 1540px;
    height: 100px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 50px;
    text-align: left;
    font-style: normal;
  }
  .box2-bottom {
    width: 1660px;
    // background-color: orange;
    margin: 10px auto;

    .box2-bottom-div {
      // display: flex;
      // justify-content: center;
      align-content: center;
      margin-left: 20px;
      width: 400px;
      height: 360px;
      background: #ffffff;
      border-radius: 20px;
      // overflow: hidden;
      text-align: center;
      // background-color: greenyellow;
      .box2-bottom-imgs {
        // align-items: center;
        // margin-top: 90px;

        width: 100px;
        height: 100px;
        background-color: bisque;
        margin: 0 auto;
      }
      .box2-bottom-text {
        font-size: 18px;
        color: #2f3963;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #333333;
        line-height: 37px;
        // text-align: left;
        font-style: normal;
      }
    }
    #box2-bottom-img1 {
      margin-bottom: 50px;
      background: url('@/assets/images/contact-img/责任担当.png') center
        no-repeat;
      background-size: 100% 100%;
    }
    #box2-bottom-img2 {
      margin-bottom: 50px;
      background: url('@/assets/images/contact-img/开放合作.png') center
        no-repeat;
      background-size: 100% 100%;
    }
    #box2-bottom-img3 {
      margin-bottom: 50px;
      background: url('@/assets/images/contact-img/卓越创新.png') center
        no-repeat;
      background-size: 100% 100%;
    }
    #box2-bottom-img4 {
      margin-bottom: 50px;
      background: url('@/assets/images/contact-img/团结包容.png') center
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
.contact-box3 {
  width: 100%;
  min-width: 880px;
  height: 621px;
  // min-height: 500px;
  background: #ffffff;
  padding-bottom: 100px;
  overflow: auto;
  // color: #fff;
  .box3-title {
    margin: 20px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .box3-content {
    font-size: 16x;
    margin: 5px auto;
    text-align: center;
    color: #333333;
  }
  .box3-center {
    width: 100%;
    // background-color: orange;
    // margin: 50px auto;
    // padding: 20px;
    display: flex;
    justify-content: center;
    .box3-div {
      width: 800px;
      height: 294px;
      cursor: pointer;
      // margin-left: 66px;
      margin-top: 60px;
      overflow: hidden;
      text-align: left;
      // background-color: #fff;
      background: url('https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2热招岗位-山东.jpg');
      background-size: 100% 100%;
      border-radius: 15px;
      position: relative;
      .box3-div-text1 {
        top: 68px;
        left: 68px;
        position: absolute;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 35px;
        color: #ffffff;
        line-height: 49px;
        text-align: left;
        font-style: normal;
      }
      .box3-div-text2 {
        position: absolute;
        left: 74px;
        bottom: 75px;
        font-size: 12px;
        color: #8d9498;
      }
    }
    .box4-div {
      width: 800px;
      height: 294px;
      cursor: pointer;
      // margin-left: 66px;
      margin-top: 60px;
      overflow: hidden;
      text-align: left;
      // background-color: #fff;
      background: url('https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2热招岗位-长沙.jpg');
      background-size: 100% 100%;
      border-radius: 15px;
      position: relative;
      .box3-div-text1 {
        top: 68px;
        left: 68px;
        position: absolute;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 35px;
        color: #ffffff;
        line-height: 49px;
        text-align: left;
        font-style: normal;
      }
      .box3-div-text2 {
        position: absolute;
        left: 74px;
        bottom: 75px;
        font-size: 12px;
        color: #8d9498;
      }
    }
  }
}
.contact-box4 {
  margin-top: 100px;
  .box3-title {
    margin: 20px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .box3-center {
    width: 100%;
    // background-color: orange;
    // margin: 50px auto;
    // padding: 20px;
    display: flex;
    justify-content: center;
    .box3-div {
      width: 800px;
      height: 240px;
      cursor: pointer;
      // margin-left: 66px;
      margin-top: 60px;
      background: #ffffff;
      border-radius: 20px;
      overflow: hidden;
      text-align: left;
      background-size: 100% 100%;
      // border-radius: 15px;
      position: relative;
    }
  }
}
.tile-bg {
  margin: 12px auto;
  width: 50px;
  height: 6px;
  background: #09ce99;
  border-radius: 4px;
}
</style>