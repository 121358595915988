import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/home.vue";
import info from "@/views/components/info.vue";
import core from "@/views/components/core.vue";
import product from "@/views/components/product.vue";
import contact from "@/views/components/contact.vue";
import realHome from "@/views/components/realHome.vue";
import project from "@/views/components/project.vue";
// import achievement from "../views/components/achievement.vue";
import achievement from "@/views/components/achievement.vue";
// achievement;
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/realHome",
    component: HomeView,
    children: [
      {
        path: "/realHome",
        name: "realHome",
        component: realHome,
      },
      {
        path: "/info",
        name: "info",
        component: info,
      },
      {
        path: "/core",
        name: "core",
        component: core,
      },
      {
        path: "/product",
        name: "product",
        component: product,
      },
      {
        path: "/contact",
        name: "contact",
        component: contact,
      },
      {
        path: "/project",
        name: "project",
        component: project,
      },
      {
        path: "/achievement",
        name: "achievement",
        component: achievement,
      },
      // achievement
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
