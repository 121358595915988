<template>
  <div class="core-container" style="background: #f7f8f9; padding-bottom: 50px">
    <div class="core-box1">
      <div class="core-title">核心技术</div>
    </div>
    <div class="core-box2">
      <div class="box2-title">集成开发能力</div>
      <div class="tile-bg"></div>
      <div class="small-des">高可扩展性无人车软硬件平台集成开发能力</div>
      <div class="box2-img"></div>
    </div>
    <div class="core-box3">
      <div class="box3-title">线控底盘技术</div>
      <div class="tile-bg"></div>
      <div class="small-des">毫秒级响应的线控底盘技术</div>

      <div class="box3-container">
        <div class="box3-chassis"></div>
      </div>
    </div>
    <div class="core-box4">
      <div class="box2-title">自动驾驶算法</div>
      <div class="tile-bg"></div>
      <div class="tab">
        <tabs
          @contentIndex="reContent"
          :contentList="contentList"
          :tabsWidth="'1100px'"
          :tabsItemWidth="'220px'"
          style="margin: 40px auto; display: flex; justify-content: center"
        ></tabs>
      </div>
      <div class="change-tab-page">
        <div class="change-tab-page-row">
          <div style="height: 506px; width: 920px" class="change-tab-page-col">
            <div>
              <div>
                <div class="tab-title">{{ tabValue.title }}</div>
                <div class="tab-content">
                  {{ tabValue.content }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <img :src="tabValue.imgSrc" class="image-container" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabs from '../../components/tabs.vue';
export default {
  components: { tabs },
  data() {
    return {
      tabValue: '',
      contentList: [
        {
          name: '高精地图',
          title: '大范围高精度地图建模',
          content:
            '能够有效融合车载里程计、陀螺仪和GPS/北斗卫星信号，自动构建百万平米级别的超大规模高精度地图，平均精度达10cm。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2自动驾驶算法1.jpg',
        },
        {
          name: '高精定位',
          title: '多源异构信息融合的高精度定位',
          content:
            '能够有效融合车载里程计、陀螺仪、GPS/北斗卫星信号和高精地图等多传感器信息，实现室内外无缝衔接的无人车高精度导航定位，综合定位精度可达15cm，输出频率50Hz。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2自动驾驶算法2.jpg',
        },
        {
          name: '路径规划',
          title: '车辆实时动态路径规划',
          content:
            '具有栅格、拓扑、势场等多项路径规划技术，能够解决城市级别的全局路径规划问题，局部规划兼容TEB和OSQP优化器，规划算法响应时间≤100ms。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2自动驾驶算法3.jpg',
        },
        {
          name: '控制响应',
          title: '高精度轨迹跟踪控制',
          content:
            '采用转向、速度耦合控制，有效实现车道保持、车辆跟随、贴边清扫、精准停靠等场景需求，控制算法响应时间≤20ms。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2自动驾驶算法4.jpg',
        },
        {
          name: '域控制器',
          title: '自主研发域控制器',
          content:
            '采用多核ARM与英伟达XavierGPU并存的异构方案，支持浮点型运算及视觉图像处理并行运算，算力可达100TOPS，以较小的算力代价搭载自研的自动驾驶核心算法，可广泛应用于功能型无人车、无人矿山、无人港口等领域产品。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2自动驾驶算法5.jpg',
        },
      ],
    };
  },
  methods: {
    reContent(val) {
      this.tabValue = val;
      // console.log('接收数据', val);
    },
  },
};
</script>
<style lang="scss" scoped>
.core-box1 {
  width: 100%;
  min-width: 880px;
  height: 600px;
  min-height: 500px;
  margin-top: 0px !important;
  // background-color: #545c64;
  background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2核心技术.jpg')
    center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  overflow: hidden;
  color: #fff;
  .core-title {
    margin: 0px auto;
    margin-top: 244px;
    // margin-bottom: 5vh;
    font-size: 80px;
    // font-weight: 700;
    text-align: center;
  }
  .core-text {
    width: 700px;
    height: 200px;
    // background-color: orange;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
  }
}
.core-box2 {
  width: 100%;
  min-width: 880px;
  min-height: 500px;
  // background-color: aqua;
  // background-color: #fff;
  overflow: auto;
  color: #333333;

  padding: 100px 0px 50px 0px;

  .box2-title {
    // font-size: 40px;
    margin: 0px auto;
    // text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .tile-bg {
    margin: 12px auto;
    width: 50px;
    height: 6px;
    background: #09ce99;
    border-radius: 4px;
  }
  .tab {
    width: 100%;
    height: 100px;
  }
  .small-des {
    margin: 25px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }
  .box2-img {
    width: 1680px;
    height: 627px;
    // background-color: chartreuse;
    margin: 20px auto;
    background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2集成开发能力.jpg')
      center no-repeat;
    background-size: 100% 100%;
  }
}
.core-box3 {
  width: 100%;
  min-width: 880px;
  min-height: 500px;
  // background-color: blueviolet;
  // background-color: #fff;
  overflow: auto;
  color: #333333;

  padding: 50px 0px 50px 0px;
  .box3-title {
    // font-size: 40px;
    margin: 0px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .tile-bg {
    margin: 12px auto;
    width: 50px;
    height: 6px;
    background: #09ce99;
    border-radius: 4px;
  }
  .small-des {
    margin: 25px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }
  .box3-container {
    // width: 700px;
    // background: #000;
    // margin: 5px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .box3-chassis {
      margin: 0 auto;
      width: 1680px;
      height: 665px;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2线控底盘技术.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    .box3-div {
      width: 40%;
      padding: 20px;
      text-align: center;
      margin-top: 0px;
      // overflow: hidden;
      .box3-img {
        width: 80px;
        height: 80px;
        background-color: bisque;
        margin: 10px auto;
      }

      .box3-text-1 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 5px;
      }
      .box3-text-2 {
        margin-top: 5px;
        font-size: 14px;
        text-align: center;
        color: #2f3963;
      }
    }
    #box3-img1 {
      background: url('@/assets/images/core-img/矩形(2).png') center no-repeat;
      background-size: 100% 100%;
    }
    #box3-img2 {
      background: url('@/assets/images/core-img/矩形(7).png') center no-repeat;
      background-size: 100% 100%;
    }
    #box3-img3 {
      background: url('@/assets/images/core-img/矩形(3).png') center no-repeat;
      background-size: 100% 100%;
    }
    #box3-img4 {
      background: url('@/assets/images/core-img/矩形(4).png') center no-repeat;
      background-size: 100% 100%;
    }
    #box3-img5 {
      background: url('@/assets/images/core-img/矩形(5).png') center no-repeat;
      background-size: 100% 100%;
    }
    #box3-img6 {
      background: url('@/assets/images/core-img/矩形(6).png') center no-repeat;
      background-size: 100% 100%;
    }
    .sss {
      display: block;
      justify-content: center;
      align-content: center;
    }
  }
}
.core-box4 {
  width: 100%;
  // min-width: 880px;
  // min-height: 500px;
  // background-color: aqua;
  // background-color: #fff;
  // overflow: auto;
  color: #333333;
  // width: 1680px;
  // height: 506px;
  // background: #ffffff;
  border-radius: 20px;

  padding: 50px 0px 0px 0px;

  .box2-title {
    // font-size: 40px;
    margin: 0px auto;
    // text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .tile-bg {
    margin: 12px auto;
    width: 50px;
    height: 6px;
    background: #09ce99;
    border-radius: 4px;
  }
  .tab {
    // width: 100%;
    height: 100px;
  }
  .change-tab-page {
    margin: 20px auto;
    // display: flex;
    // display: flex;
    // justify-content: flex-end;
    // justify-content: center;
    // align-items: center;

    width: 1680px;

    // margin-bottom: 150px;
    background: #ffffff;
    border-radius: 20px;
    .change-tab-page-row {
      height: 506px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      .change-tab-page-col {
        // margin: 20px auto;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 506px;
        .tab-title {
          width: 480px;
          // height: 40px;
          // margin: 20px auto;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 28px;
          color: #333333;
          line-height: 40px;
          text-align: left;
          font-style: normal;
          // margin: 20px auto;
        }
        .tab-content {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          margin: 20px auto;
          margin-top: 30px;
          width: 600px;

          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 50px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
    .image-container {
      top: 0px;
      right: 0px;
      height: 506px;
      width: 760px;
    }
  }
  .small-des {
    margin: 25px auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }
  .box2-img {
    width: 1680px;
    height: 627px;
    // background-color: chartreuse;
    margin: 20px auto;
    background: url('@/assets/images/core-img/矩形.png') center no-repeat;
    background-size: 100% 100%;
  }
}
</style>