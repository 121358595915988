<template>
  <div class="switch-container">
    <div class="box" :style="{ width: tabsWidth }">
      <div
        class="box-item"
        :style="{ width: tabsItemWidth }"
        v-for="(item, index) in contentList"
        :key="item.name"
        @mouseover="handleSelected(index, item)"
        :class="{ active: selectId === index }"
      >
        {{ item.name }}
        <!-- :style="{ color: selectId === index ? '#ff5f16' : '#333'}" -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentList: {
      type: Array,
      default: () => [],
    },
    tabsWidth: { type: String, default: () => {} },
    tabsItemWidth: { type: String, default: () => {} },
  },
  data() {
    return {
      selectId: 0,
      list: [],
    };
  },
  created() {
    this.$emit('contentIndex', this.contentList[0]);
  },
  methods: {
    handleSelected(e, item) {
      this.selectId = e;
      if (item) {
        this.$emit('contentIndex', item);
      } else {
        this.$emit('contentIndex', list[0]);
      }
      console.log(this.selectId);
    },
  },
};
</script>>

<style lang="scss">
.switch-container {
  .box {
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border-radius: 40px;
    // width: 1100px;
    // padding: 20px;
    padding: 5px;
    // border-radius: 70px;
    font-size: 24px;
    .box-item {
      cursor: pointer;
      padding: 20px 25px;
      border-radius: 70px;
      text-align: center;
      width: 220px;
    }
  }
  .active {
    cursor: pointer;
    background: linear-gradient(270deg, #33d1b5 0%, #17a594 100%);
    border-radius: 40px 100px 100px 40px;
    color: #fff;
    transition: background-color 0.7s ease-in-out;
  }
}
</style>