import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import VueLazyload from "vue-lazyload";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/element-variables.scss";
import "./assets/images/icon/iconfont.css";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import "element-ui/lib/theme-chalk/base.css";
// import "./lib-flexible"
// import "./untils/rem";
import "lib-flexible/flexible.js";
// import "amfe-flexible";
Vue.config.productionTip = false;
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.use(ElementUI);
//  {
//   preLoad: 1.3,
//   attempt: 1, // 加载图片数量
//   listenEvents: [
//     "scroll",
//     "wheel",
//     "mousewheel",
//     "resize",
//     "animationend",
//     "transitionend",
//     "touchmove",
//   ],
// }
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
Vue.use(VueLazyload);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
