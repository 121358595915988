<template>
  <div class="info-container" style="background: #f7f8f9; padding-bottom: 50px">
    <div class="info-box1">
      <div class="info-title">公司简介</div>
      <div class="info-text"></div>
    </div>
    <div class="info-box2" style="height: 692px; margin-top: 89px">
      <div class="box2-title" style="margin-top: 89px">公司介绍</div>
      <div class="tile-bg"></div>
      <div class="box2-text">
        <p class="info-p" style="margin-top: 35px">
          睿羿科技成立于2021年，是一家提供无人驾驶智能装备、域控制器及解决方案的科技公司，是山东省智能网联汽车技术创新中心孵化的具有独立法人资格的高科技企业。公司成立以来获评淄博市工程技术研究中心、山东省创新型中小企业及国家高新技术企业等荣誉资质，并在第十二届中国创新创业大赛（山东赛区）中取得优胜成绩。
        </p>
        <br />
        <p class="info-p">
          公司团队核心成员来自于清华大学、国防科技大学、百度、京东等国内重点高校和知名企业，目前团队成员中拥有高级职称人数3名、淄博英才2名、泰山创业领军人才3名，具备研发试验、生产制造、质量管理、市场营销、运营服务等全流程管理能力。睿羿科技瞄准特定场景下专用智能装备领域，按照"1+N"（即：统一平台+多种场景），提供面向特定场景的无人驾驶全栈式解决方案，用人工智能和自动驾驶技术赋能传统制造业以及智慧城市的发展，现已研发出无人环卫车、无人物流车、无人接驳车、无人售卖车、无人巡防车等无人智能装备产品。
        </p>
      </div>
    </div>
    <!-- <el-card> -->
    <div class="info-box2">
      <div class="box2-title" style="margin-top: 89px">公司成果</div>
      <div class="tile-bg"></div>
      <div class="box2-text1" style="height: 200px">
        <p class="info-p" style="margin-top: 35px">
          睿羿科技以可编程线控底盘技术及自动驾驶核心算法为基础，自主研发出具有毫秒级响应的线控底盘和域控制器设备，掌握了百万平米级大范围高精度地图建模技术、多源异构信息融合的高精度定位技术、车辆实时动态路径规划技术及转向/速度紧耦合的高精度轨迹跟踪控制技术等多项核心关键技术，目前拥有核心自主知识产权30余项。公司生产了无人环卫车、无人物流车、无人接驳车、无人售卖车、无人巡防车等多款功能型无人车产品，在无人驾驶车辆装备领域内的出货量和市场占有率居行业前列。
        </p>
        <!-- <el-button
          round
          class="home-check-more"
          @click="pushAchievement"
          size="small"
          >查看更多<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button> -->
        <div class="box2-img"></div>
      </div>
    </div>
    <!-- </el-card> -->

    <div class="info-box3">
      <div class="box3-title" style="margin-top: 89px">公司规模</div>
      <div class="tile-bg"></div>
      <div class="box3-text">
        <p class="info-p" style="text-align: justify; margin-top: 35px">
          睿羿科技已拥有占地2万平米的现代化柔性制造生产车间，依托淄博市智能网联汽车产业园内占地36亩的自动驾驶低速封闭测试场（含18种路面、54种测试场景），可自主完成30余种自动驾驶场景的检测项目，具有研发、实验、生产、检测、测试全链条闭环功能，实现无人驾驶车辆及线控底盘年产能5万台。依托山东省"十强产业"行动计划，在国内率先开展"千台级"规模的自动驾驶智能装备示范运营工作。
        </p>
        <!-- <p class="info-p" style="text-align: justify">
          公司团队核心成员来自于清华大学、国防科技大学、百度、京东等国内重点高校和知名企业，具备研发试验、生产制造、质量管理、市场营销、运营服务等全链条管理能力。目前，公司已自主研制出无人环卫、无人接驳、无人零售、无人安防和无人物流等新能源智能无人装备产品，依托山东省“十强产业”行动计划，在国内率先开展“千台级”规模的自动驾驶智能装备示范运营工作。
        </p> -->
        <!-- <p class="info-p" style="text-align: justify">
          公司团队核心成员来自于清华大学、北京航空航天大学、国防科技大学、百度、京东等国内重点高校和知名企业，具备研发试验、生产制造、质量管理、市场营销、运营服务等全链条管理能力。目前，公司已自主研制出无人环卫、无人接驳、无人零售、无人安防和无人物流等新能源智能无人装备产品，依托山东省“十强产业”行动计划，在国内率先开展“千台级”规模的自动驾驶智能装备示范运营工作。
        </p> -->
      </div>
      <div class="img-list" style="margin-top: 50px">
        <div class="box3-img"></div>
        <div class="box3-img1"></div>
        <div class="box3-img2"></div>
      </div>
      <div class="img-list1">
        <!-- <div class="box3-img2"></div> -->
        <div class="box3-img3"></div>
        <div class="box3-img4"></div>
        <div class="box3-img5"></div>
      </div>
      <!-- <div class="img-list2"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'info',
  data() {
    return {
      imgshow: true,
    };
  },
  methods: {
    pushAchievement() {
      this.$router.push('/achievement');
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box1 {
  width: 100%;
  min-width: 880px;
  height: 600px;
  min-height: 500px;

  // margin-top: 0px !important;
  // background-color: #545c64;
  background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司简介.jpg')
    center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  overflow: hidden;
  color: #fff;
  .info-title {
    margin: 0px auto;
    margin-top: 244px;
    // margin-bottom: 5vh;
    font-size: 80px;
    // font-weight: 700;
    text-align: center;
  }
  .info-text {
    width: 700px;
    height: 256px;
    // background-color: orange;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
  }
}
.info-box2 {
  // width: 100%;
  width: 1680px;
  height: 1140px;
  margin: 35px auto;
  background: #ffffff;
  border-radius: 11px;
  // background-color: aqua;
  overflow: hidden;
  // padding: 20px;

  // color: #fff;
  .box2-title {
    // font-size: 40px;
    margin: 0px auto;
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
  }
  .tile-bg {
    margin: 12px auto;
    width: 50px;
    height: 6px;
    background: #09ce99;
    border-radius: 4px;
  }
  .box2-text {
    width: 1540px;
    height: 380px;
    margin: 35px auto;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 50px;
    text-align: justify;
    font-style: normal;
  }
  .box2-text1 {
    width: 1540px;
    height: 380px;
    margin: 0px auto;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 50px;
    text-align: justify;
    font-style: normal;
  }
  .box2-img {
    width: 1284px;
    height: 723px;
    // background-color: chartreuse;
    margin: 0px auto;
    background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司成果.jpg')
      center no-repeat;
    background-size: 100% 100%;
  }
}
.info-box3 {
  width: 1680px;
  height: 1044px;
  background: #ffffff;
  border-radius: 11px;
  // background-color: #eaeef1;
  margin: 10px auto;
  overflow: auto;
  // padding: 20px 0px 20px 0px;

  // color: #fff;
  .box3-title {
    // font-size: 40px;
    margin: 0px auto;
    text-align: center;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
  }
  .tile-bg {
    margin: 12px auto;
    width: 50px;
    height: 6px;
    background: #09ce99;
    border-radius: 4px;
  }
  .box3-text {
    width: 1540px;
    height: 150px;
    // text-indent: 2em;
    margin: 10px auto;
    font-weight: 400;
    font-size: 24px;
    color: #333333;
    line-height: 50px;
    text-align: justify;
    font-style: normal;
    // text-align: center;

    // background-color: beige;
  }
  .img-list {
    // width: 800px;
    display: flex;
    align-self: center;
    flex-flow: row wrap;
    justify-content: center;
    .box3-img {
      width: 502px;
      height: 256px;
      // padding: 10px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模1.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    .box3-img1 {
      width: 502px;
      height: 256px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模2.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    .box3-img2 {
      width: 502px;
      height: 256px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模3.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
  }
  .img-list1 {
    display: flex;
    align-self: center;
    flex-flow: row wrap;
    justify-content: center;

    .box3-img2 {
      width: 502px;
      height: 256px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模4.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    .box3-img3 {
      width: 502px;
      height: 256px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模4.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    .box3-img4 {
      width: 502px;
      height: 256px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模5.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
    .box3-img5 {
      width: 502px;
      height: 256px;
      margin: 10px;
      // justify-content: center;
      // background-color: chartreuse;
      // margin: 20px auto;
      background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2公司规模6.jpg')
        center no-repeat;
      background-size: 100% 100%;
    }
  }
  .img-list2 {
    display: flex;
    align-self: center;
    flex-flow: row wrap;
    justify-content: center;
  }
}
</style>