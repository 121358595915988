<template>
  <div class="info-container" style="background: #f7f8f9; padding-bottom: 50px">
    <div class="info-box1">
      <div class="info-title">项目案列</div>
    </div>
    <div class="info-box2" style="">
      <div class="box2-title" style="margin-top: 90px">项目运行</div>
      <div class="tile-bg"></div>
      <div class="box2-text">
        <p class="info-p">
          从2022年3月开始，睿羿科技组建运营团队开展了无人车的部署和测试运行，先后实现了临淄经开区智能网联汽车产业园、淄博区人民广场、湖南松雅湖国家湿地公园、宜宾国际竹产品交易中心、呼和浩特蒙牛和林基地等典型场景的部署与运行，目前已在济南、淄博、长沙、重庆、宜宾、呼和浩特等全国多个地区开展常态化示范运营，服务时长超一年，月均运营里程超800公里，累计运营里程超10000公里。
        </p>
      </div>
    </div>
    <div class="project-content">
      <div style="width: 680px; margin: 56px auto">
        <div class="project-content-title" style="">
          湖南长沙
          <div class="project-content-title-bg"></div>
          <div class="project-content-small-title">溪悦荟清扫车项目</div>
          <div class="project-content-des">
            2023年6月，无人清扫车落地溪悦荟，开展为期1个月的试运营工作，将无人驾驶清扫作业融入城市常态化运行服务体系，为城市环卫管理探索了新模式。试运营期间，无人清扫车提供道路清扫、洒水降尘等多种城市环卫作业服务，可连续作业4小时以上，工作效率达3000m²/h，相当于3个以上环卫工人共同作业的工作量，有效缓解了当地保洁的清扫压力。
          </div>
        </div>
      </div>
      <div>
        <div class="box3-img">
          <div>
            <img
              id="playvd"
              v-if="imgshow"
              alt=""
              src="https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2溪悦荟清扫车项目.jpg"
              class="playv"
              style="border-radius: 0px 20px 20px 0"
            />
            <span class="el-icon-bofang1" v-if="imgshow" @click="openVideo"
              ><i class="el-icon-caret-right"></i
            ></span>
            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
          <div class="videobox" id="videobox">
            <video
              class="videoWH"
              controls="controls"
              id="video"
              ref="videoPlay"
            >
              <source
                src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=长沙溪悦荟清扫车项目.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <div>
        <div class="box3-img">
          <div>
            <img
              id="playvd"
              alt=""
              src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2国际竹产品交易中心清扫-安防项目.jpg"
              class="playv"
              style="border-radius: 20px 0px 0px 20px"
            />

            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
        </div>
      </div>
      <div style="width: 680px">
        <div class="project-content-title">
          四川宜宾
          <div class="project-content-title-bg"></div>
          <div class="project-content-small-title">
            国际竹产品交易中心清扫-安防项目
          </div>
          <div class="project-content-des" style="margin: 0px auto">
            作为长江工业园数字交通示范路段建设参与者之一，我司在国际竹产品交易中心投放了无人安防车和无人清扫车两款产品，并在第一时间打通了和联通MQTT云平台的接口，构建出3D数字孪生场景。利用车路协同云控平台建立实时路况的虚拟场景，园区管理方可在第一时间获取道路交通信息、车辆运行情况及各种设备的运行状态。在2023年6月8日至11日世界动力电池大会召开期间，维持了会场的清洁与安全，获得了大量好评。
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <div style="width: 680px">
        <div class="project-content-title">
          重庆九龙坡区
          <div class="project-content-title-bg"></div>
          <div class="project-content-small-title">
            西部（重庆）科学城清扫车项目
          </div>
          <div class="project-content-des">
            2023年6月，睿羿无人清扫车落地西部（重庆）科学城，开展常态化运营。并于同年8月，在重庆车辆检测研究院顺利通过了功能型无人车自动驾驶功能场地测试标准。
          </div>
        </div>
      </div>
      <div>
        <div class="box3-img">
          <div>
            <img
              id="playvd"
              style="border-radius: 0px 20px 20px 0"
              v-if="imgshow1"
              alt=""
              src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2西部（重庆）科学城清扫车项目.jpg"
              class="playv"
            />
            <span class="el-icon-bofang1" v-if="imgshow1" @click="openQCVideo"
              ><i class="el-icon-caret-right"></i
            ></span>
            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
          <div class="videobox" id="videobox">
            <video
              class="videoWH"
              controls="controls"
              id="video"
              ref="videoQCPlay"
            >
              <source
                src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=西部（重庆）科学城清扫车项目.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <div>
        <div class="box3-img">
          <div>
            <img
              id="playvd"
              v-if="imgshow2"
              alt=""
              style="border-radius: 20px 0px 0px 20px"
              src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2蒙牛基地观光车项目.jpg"
              class="playv"
            />
            <span class="el-icon-bofang1" v-if="imgshow2" @click="openMNVideo"
              ><i class="el-icon-caret-right"></i
            ></span>
            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
          <div class="videobox" id="videobox">
            <video
              class="videoWH"
              controls="controls"
              id="video"
              ref="videoMNPlay"
            >
              <source
                src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=呼和浩特蒙牛基地观光车项目.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <div style="width: 680px">
        <div class="project-content-title">
          内蒙古呼和浩特
          <div class="project-content-title-bg"></div>
          <div class="project-content-small-title">蒙牛基地观光车项目</div>
          <div class="project-content-des">
            蒙牛集团呼和浩特总部智能工厂共部署4台自动驾驶观光车，观光路线总里程为1.8km，总时长30分钟。该车具备狭窄通道情况下通过直角弯的能力（最窄的过弯通道宽度仅2.8米），多车编队跟车距离为3m，安全停车距离为2m。初步试验效果获得了甲方的肯定。
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <div style="width: 680px">
        <div class="project-content-title">
          湖南长沙
          <div class="project-content-title-bg"></div>
          <div class="project-content-small-title">松雅湖接驳车项目</div>
          <div class="project-content-des">
            松雅湖公园共部署3台自动驾驶接驳车，环湖路线总里程为10km，平均车速18km/h，串联了礼乐广场、湿地广场、金银沙滩、关羽广场、松雅书院五个景点。该车具备超车、跟车、避障等自主能力，可满足复杂场景（含：行人、自行车、电瓶车、观光车、滑板车、宠物等）需求，有效缓解了园区内运力紧张的情况。
          </div>
        </div>
      </div>
      <div>
        <div class="box3-img">
          <div>
            <img
              id="playvd"
              style="border-radius: 0px 20px 20px 0"
              v-if="imgshow3"
              alt=""
              src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2松雅湖接驳车项目.jpg"
              class="playv"
            />
            <span class="el-icon-bofang1" v-if="imgshow3" @click="openSYHVideo"
              ><i class="el-icon-caret-right"></i
            ></span>
            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
          <div class="videobox" id="videobox">
            <video
              class="videoWH"
              controls="controls"
              id="video"
              ref="videoSYHPlay"
            >
              <source
                src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=长沙松雅湖接驳车项目.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="project-content">
      <div>
        <div class="box3-img">
          <div>
            <img
              id="playvd"
              style="border-radius: 20px 0px 0px 20px"
              v-if="imgshow4"
              alt=""
              src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=v2临淄经开区智能网联汽车产业园项目.jpg"
              class="playv"
            />
            <span class="el-icon-bofang1" v-if="imgshow4" @click="openSDVideo"
              ><i class="el-icon-caret-right"></i
            ></span>
            <!-- <i class="el-icon-video-play" @click="openVideo" v-if="imgshow"></i> -->
          </div>
          <div class="videobox" id="videobox">
            <video
              class="videoWH"
              controls="controls"
              id="video"
              ref="videoSDPlay"
            >
              <source
                src="https://apply.backup.realepotech.com/realecg/downGwVideo?videoFileName=编队行驶.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <div style="width: 680px">
        <div class="project-content-title">
          山东淄博
          <div class="project-content-title-bg"></div>
          <div class="project-content-small-title">
            临淄经开区智能网联汽车产业园观光车项目
          </div>
          <div class="project-content-des">
            在临淄经开区智能网联汽车产业园共部署8台自动驾驶观光车，采用2*
            4多车编队，跟车距离为3m。观光路线总里程为2km，总时长10分钟。作为该产业园特色项目，已多次接待各级领导参观，并获得一致认可。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'info',
  data() {
    return {
      isShowVideo: true,
      imgshow: true,
      imgshow1: true,
      imgshow2: true,
      imgshow3: true,
      imgshow4: true,
      videoSrc: '',
    };
  },
  methods: {
    openVideo() {
      this.imgshow = false;
      this.$refs['videoPlay'].play();
    },
    openQCVideo() {
      this.imgshow1 = false;
      this.$refs['videoQCPlay'].play();
    },
    openMNVideo() {
      this.imgshow2 = false;
      this.$refs['videoMNPlay'].play();
    },
    openSYHVideo() {
      this.imgshow3 = false;
      this.$refs['videoSYHPlay'].play();
    },
    openSDVideo() {
      this.imgshow4 = false;
      this.$refs['videoSDPlay'].play();
    },
    changeCarousel(index) {
      console.log(index);
      this.isShowVideo = true;
    },
    // openVideo(video) {
    //   this.$nextTick(() => {
    //     video.isShowVideo = false;
    //     this.isShowVideo = false;
    //   });

    //   this.videoSrc = video.videoUrl;
    //   this.$refs['videoPlay'][video.id - 1].play();
    //   console.log(
    //     '点击获取信息',
    //     video,
    //     this.$refs['videoPlay'],
    //     video.isShowVideo
    //   );
    // },
  },
};
</script>

<style lang="scss" scoped>
.info-box1 {
  width: 100%;
  min-width: 880px;
  height: 600px;
  min-height: 500px;
  margin-top: 0px !important;
  // background-color: #545c64;
  background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2项目案例.jpg')
    center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  overflow: hidden;
  color: #fff;
  .info-title {
    margin: 0px auto;
    margin-top: 244px;
    margin-bottom: 5vh;
    font-size: 80px;
    // font-weight: 700;
    text-align: center;
  }
  .info-text {
    width: 1000px;
    height: 200px;
    // background-color: orange;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
  }
}
.info-box2 {
  width: 1680px;
  height: 462px;
  background: #ffffff;
  border-radius: 20px;
  margin: 50px auto;
  // width: 100%;
  // height: 100%;
  min-width: 880px;
  min-height: 500px;
  // background-color: #fff;
  // background-color: aqua;
  overflow: hidden;
  // padding: 50px 0px 50px 0px;

  // color: #fff;
  .box2-title {
    margin: 0px auto;
    // text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  // .box2-title {
  //   font-weight: 600;
  //   font-size: 28px;
  //   color: #262626;
  //   line-height: 39px;
  //   text-align: left;
  //   font-style: normal;
  // }
  .tile-bg1 {
    margin-top: 12px;
    width: 28px;
    height: 3px;
    background: #09ce99;
    border-radius: 2px;
  }
  .box2-text {
    width: 100%;
    margin: 25px auto;
  }
  .box2-img {
    width: 1000px;
    height: 563px;
    // background-color: chartreuse;
    margin: 20px auto;
    background: url('@/assets/images/info-img/位图.png') center no-repeat;
    background-size: 100% 100%;
  }
}
.info-p {
  margin: 25px auto;
  width: 1540px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  line-height: 50px;
  text-align: left;
  font-style: normal;
}
.box2-container {
  width: 1000px;
  height: 563px;
  // margin: 0 auto;
  // padding-top: 9px;
  .box2-img-box {
    width: 100%;
    height: 100%;
    // background-color: aquamarine;
  }
  .box3-img .playv {
    width: 1000px;
    height: 563px;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .box3-img .videobox .videoWH {
    width: 1000px;
    height: 563px;
  }
  .box3-img:hover .playv {
    width: 1000px;
  }
  .box3-img:hover span {
    color: #ffff;
    opacity: 0.7;
  }
  .playv {
    width: 1000px;
    height: 563px;
    // background-color: chartreuse;
    // background: url('@/assets/images/info-img/位图(1).png') center no-repeat;
    background-size: 100% 100%;
  }
  .box3-img .el-icon-bofang1 {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    text-align: center;
    left: 325px;
    opacity: 0.5;
    line-height: 40px;
    top: 175px;
    font-size: 30px;
    position: absolute;
    background: #000000;

    // color: #fff;
    cursor: pointer;
  }
  .box3-img {
    width: 1000px;
    height: 563px;
    // background-color: chartreuse;
    // margin: 20px auto;
    // background: url('@/assets/images/info-img/位图(1).png') center no-repeat;
    background-size: 100% 100%;
    padding: 0;
    // background: #fff;
    overflow: hidden;
    position: relative;
  }
}
.el-icon-caret-right {
  color: #ffff !important;
  text-align: center;
  opacity: 1 !important;
}
.tile-bg {
  margin: 12px auto;
  width: 50px;
  height: 6px;
  background: #09ce99;
  border-radius: 4px;
}
.project-content {
  width: 1680px;
  height: 563px;
  background: #ffffff;
  border-radius: 20px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // justify-content: center;
  .project-content-title {
    margin: 20px auto;
    width: 580px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 40px;
    color: #262626;
    line-height: 56px;
    text-align: left;
    font-style: normal;
    .project-content-title-bg {
      width: 50px;
      height: 6px;
      background: #09ce99;
      border-radius: 4px;
      margin-top: 12px;
    }
    .project-content-small-title {
      width: 580px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #333333;
      line-height: 40px;
      margin: 20px auto;
      // text-align: left;
      font-style: normal;
      margin-top: 35px;
    }
    .project-content-des {
      margin: 20px auto;
      width: 580px;
      // height: 280px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 40px;
      text-align: justify;
      font-style: normal;
    }
  }

  .box3-img .playv {
    width: 1000px;
    height: 563px;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .box3-img .videobox .videoWH {
    width: 1000px;
    height: 563px;
  }
  .box3-img:hover .playv {
    width: 1000px;
  }
  .box3-img:hover span {
    color: #ffff;
    opacity: 0.7;
  }
  .playv {
    width: 1000px;
    height: 563px;
    // background-color: chartreuse;
    // background: url('../../assets/images/info-img/位图(1).png') center no-repeat;
    background-size: 100% 100%;
  }
  .box3-img {
    width: 1000px;
    height: 563px;
    // background-color: chartreuse;
    margin: 50px auto;
    // background: url('../../assets/images/info-img/位图(1).png') center no-repeat;
    background-size: 100% 100%;
    padding: 0;
    // background: #fff;
    overflow: hidden;
    position: relative;
    .el-icon-bofang1 {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      color: #fff;
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 100px;
      text-align: center;
      opacity: 0.5;
      line-height: 100px;
      font-size: 60px;
      position: absolute;
      background: #000000;
      .el-icon-caret-right {
        color: #fff;
        opacity: 1;
      }
    }
  }
}
</style>