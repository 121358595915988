<template>
  <div
    class="product-container"
    style="background: #f7f8f9; padding-bottom: 100px"
  >
    <div class="product-box1">
      <div class="product1-title">主要产品</div>
      <!-- <div class="product-text">
        已研发出无人环卫车、无人物流车、无人接驳车、无人售卖车、无人巡防车等无人智能装备产品。
      </div> -->
    </div>
    <div class="product-box2">
      <div class="box2-title">产品简介</div>
      <div class="tile-bg"></div>
      <div class="tab">
        <tabs
          @contentIndex="reContent"
          :contentList="contentList"
          :tabsWidth="'1680px'"
          :tabsItemWidth="'280px'"
          style="margin: 40px auto; display: flex; justify-content: center"
        ></tabs>
      </div>
      <div class="car-type">
        <div class="car-type-content">
          <span class="car-type-title-bg"></span>
          <span class="car-type-title">{{ tabValue.name }}</span>
          <img
            src="../../assets/images/product-img/圆圈.png"
            style="margin-left: auto"
          />
        </div>
        <div class="car-type-content1">
          <el-row style="" class="car-type-content1-row">
            <el-col :span="12"
              ><img
                :src="tabValue.imgSrc"
                style=""
                class="car-type-content1-img"
            /></el-col>
            <el-col :span="12"
              ><span class="pro-item" style="">{{ tabValue.content }}</span>
              <br />
              <span class="pro-cont-item" style="">{{
                tabValue.content1
              }}</span>
              <br />
              <span class="pro-cont-item1">{{ tabValue.content2 }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="product-type">
        <div class="car-type-content">
          <span class="car-type-title-bg"></span>
          <span class="car-type-title">产品功能</span>
          <img
            src="../../assets/images/product-img/圆圈.png"
            style="margin-left: auto"
          />
        </div>
        <div style="" class="product-type-row">
          <el-row type="flex" justify="space-around">
            <el-col
              :span="3"
              class="product-type-col"
              style=""
              v-for="(item, index) in tabValue.productList"
              :key="index"
            >
              <img :src="item.picSrc" class="product-type-img" />
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-around">
            <el-col
              :span="3"
              style="display: flex; justify-content: center"
              v-for="(item, index) in tabValue.productList"
              :key="index"
            >
              <span class="product-title">{{ item.title }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="car-parameters">
        <div class="car-type-content">
          <span class="car-type-title-bg"></span>
          <span class="car-type-title">产品参数</span>
          <img
            src="../../assets/images/product-img/圆圈.png"
            style="margin-left: auto"
          />
        </div>
        <!--               align="center"  background: rgba(8,151,156,0.2);
border: 2px solid #FFFFFF;
opacity: 0.51;-->
        <div style="padding: 0px 50px 20px 50px">
          <el-table
            :data="tabValue.paramList"
            style="width: 100%; line-height: 80px"
            :cell-style="cellStyle"
            :header-cell-style="{
              background: '#F3FCFC !important',
              border: '0px solid #FFFFFF !important',
              paddingLeft: '128px !important',
              fontWeight: '500 !important',
              fontSize: '24px !important',
              height: '68px !important',
              color: '#096467 !important',
              lineHeight: '60px !important',
              fontStyle: 'normal !important',
            }"
          >
            <el-table-column
              prop="parameterName"
              label="参数名称"
              style="line-height: 60px"
            >
            </el-table-column>
            <el-table-column
              prop="parameterDetail"
              label="参数详情"
              style="line-height: 60px"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabs from '../../components/tabs.vue';
export default {
  components: { tabs },
  data() {
    return {
      tabValue: '',
      contentList: [
        {
          name: '无人小型清扫车',
          title: '大范围高精度地图建模',
          content: '清扫效率>3000㎡/h，相当于3个以上环卫工人的工作量。 ',
          content1: ' 已在山东、湖南、重庆、四川等地开展常态化示范运营。',
          content2: '产品目前已量产近百台，月均运营里程超500公里。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2无人小型清扫车.jpg',
          productList: [
            {
              title: '定时任务',
              picSrc: require('../../assets/images/product-img/定时任务.png'),
            },
            {
              title: '遍历清扫',
              picSrc: require('../../assets/images/product-img/遍历清扫.png'),
            },
            {
              title: '贴边清扫',
              picSrc: require('../../assets/images/product-img/贴边清扫.png'),
            },
            {
              title: '一键回家',
              picSrc: require('../../assets/images/product-img/一键回家.png'),
            },
            {
              title: '实时路径规划',
              picSrc: require('../../assets/images/product-img/实时路径规划.png'),
            },
            {
              title: '自主建图',
              picSrc: require('../../assets/images/product-img/自主建图.png'),
            },
            {
              title: '自主泊车',
              picSrc: require('../../assets/images/product-img/自主泊车.png'),
            },
            {
              title: '云端管理',
              picSrc: require('../../assets/images/product-img/云端管理.png'),
            },
          ],
          paramList: [
            {
              parameterName: '长*宽*高',
              parameterDetail: '1710mm*905mm*1200mm',
              bgColor: '1',
            },
            {
              parameterName: '贴边清扫',
              parameterDetail: '≤10cm',
              bgColor: '2',
            },
            {
              parameterName: '路径行驶精度',
              parameterDetail: '≤5cm',
              bgColor: '1',
            },
            {
              parameterName: '水箱容积',
              parameterDetail: '80L',
              bgColor: '2',
            },
            {
              parameterName: '尘箱容积',
              parameterDetail: '60L',
              bgColor: '1',
            },
            {
              parameterName: '整备质量',
              parameterDetail: '400kg',
              bgColor: '2',
            },
          ],
        },
        {
          name: '无人中型清扫车',
          title: '多源异构信息融合的高精度定位',
          content: '清扫效率>16000㎡/h，相当于8个环卫工人的工作量。',
          content1: '已交付淄博市、芜湖市等多个园区，开展常态化示范运营。',
          content2: '目前该产品已量产，月均运营里程超200公里。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2无人中型清扫车.jpg',
          productList: [
            {
              title: '人机协作',
              picSrc: require('../../assets/images/product-img/人机协作.png'),
            },
            {
              title: '云端实时监控',
              picSrc: require('../../assets/images/product-img/云端实时监控.png'),
            },
            {
              title: '贴边清扫',
              picSrc: require('../../assets/images/product-img/贴边清扫.png'),
            },
            {
              title: '一键回家',
              picSrc: require('../../assets/images/product-img/一键回家.png'),
            },
            {
              title: '实时路径规划',
              picSrc: require('../../assets/images/product-img/实时路径规划.png'),
            },
            {
              title: '自主建图',
              picSrc: require('../../assets/images/product-img/自主建图.png'),
            },
            {
              title: '实时感知预测',
              picSrc: require('../../assets/images/product-img/实时感知预测.png'),
            },
            {
              title: '灵活调度',
              picSrc: require('../../assets/images/product-img/灵活调度.png'),
            },
          ],
          paramList: [
            {
              parameterName: '长*宽*高',
              parameterDetail: '4080mm*1800mm*2050mm',
              bgColor: '1',
            },
            {
              parameterName: '贴边清扫',
              parameterDetail: '≤10cm',
              bgColor: '2',
            },
            {
              parameterName: '路径行驶精度',
              parameterDetail: '≤5cm',
              bgColor: '1',
            },
            {
              parameterName: '水箱容积',
              parameterDetail: '200L',
              bgColor: '2',
            },
            {
              parameterName: '尘箱容积',
              parameterDetail: '240L',
              bgColor: '1',
            },
            {
              parameterName: '整备质量',
              parameterDetail: '1650kg',
              bgColor: '2',
            },
          ],
        },
        {
          name: '无人消杀、安防车',
          title: '多源异构信息融合的高精度定位',
          content: '支持视频监控、火点检测、区域异常检测、动态入侵检测等。 ',
          content1: '目前安防车、消杀车已在山东、四川、湖南等地投放运营。',
          content2: '测温范围 -20℃~+550℃，精度±2℃。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2无人消杀、安防车.jpg',
          productList: [
            {
              title: '行为规范检测',
              picSrc: require('../../assets/images/product-img/行为规范检测.png'),
            },
            {
              title: '异常入侵检测',
              picSrc: require('../../assets/images/product-img/异常入侵检测.png'),
            },
            {
              title: '火情检测',
              picSrc: require('../../assets/images/product-img/火情检测.png'),
            },
            {
              title: '黑白名单识别',
              picSrc: require('../../assets/images/product-img/黑白名单识别.png'),
            },
            {
              title: '气体泄露探测',
              picSrc: require('../../assets/images/product-img/气体泄漏探测.png'),
            },
            {
              title: '车牌识别',
              picSrc: require('../../assets/images/product-img/车牌识别.png'),
            },
            {
              title: '智能云端管理',
              picSrc: require('../../assets/images/product-img/云端管理.png'),
            },
            {
              title: '云端实时监控',
              picSrc: require('../../assets/images/product-img/云端实时监控.png'),
            },
          ],
          paramList: [
            {
              parameterName: '长*宽*高',
              parameterDetail: '1820mm*870mm*1760mm',
              bgColor: '1',
            },
            {
              parameterName: '测温区间',
              parameterDetail: '-20℃~+550℃',
              bgColor: '2',
            },
            {
              parameterName: '电池容量',
              parameterDetail: '3.2Kwh',
              bgColor: '1',
            },
            {
              parameterName: '最高车速',
              parameterDetail: '30km/h',
              bgColor: '2',
            },
            {
              parameterName: '转弯半径',
              parameterDetail: '2m',
              bgColor: '1',
            },
            {
              parameterName: '整备质量',
              parameterDetail: '400kg',
              bgColor: '2',
            },
          ],
        },
        {
          name: '无人驾驶接驳车',
          title: '多源异构信息融合的高精度定位',
          content: '支持多站点停靠、语音报站等功能。 ',
          content1:
            '目前产品已在淄博市太公湖公园、蒙牛和林基地等多地落地运营。 ',
          content2: ' 累计运营里程近1000公里，接待旅客数超8000人。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2无人驾驶接驳车.jpg',
          productList: [
            {
              title: '自动循环接驳',
              picSrc: require('../../assets/images/product-img/自动循环接驳.png'),
            },
            {
              title: '站点停靠',
              picSrc: require('../../assets/images/product-img/站点停车.png'),
            },
            {
              title: '云端管理',
              picSrc: require('../../assets/images/product-img/云端管理.png'),
            },
            {
              title: '避障行车',
              picSrc: require('../../assets/images/product-img/自主避障.png'),
            },
            {
              title: '云端实时监控',
              picSrc: require('../../assets/images/product-img/云端实时监控.png'),
            },
            {
              title: '通过路口',
              picSrc: require('../../assets/images/product-img/通过路口.png'),
            },
            {
              title: '语音播报',
              picSrc: require('../../assets/images/product-img/语音播报.png'),
            },
            {
              title: '跟车行驶',
              picSrc: require('../../assets/images/product-img/跟车行驶.png'),
            },
          ],
          paramList: [
            {
              parameterName: '转弯半径',
              parameterDetail: '6m/4.5m/5.5m',
              bgColor: '1',
            },
            {
              parameterName: '整备质量',
              parameterDetail: '1500kg/800kg/1450kg',
              bgColor: '2',
            },
            {
              parameterName: '乘坐人数',
              parameterDetail: '8人/8人/14人',
              bgColor: '1',
            },
            {
              parameterName: '最高车速',
              parameterDetail: '30km/h',
              bgColor: '2',
            },
            {
              parameterName: '充电时间',
              parameterDetail: '8-10h',
              bgColor: '1',
            },
            {
              parameterName: '最大续航里程',
              parameterDetail: '≥100km',
              bgColor: '2',
            },
          ],
        },
        {
          name: '无人奶茶、咖啡车',
          title: '多源异构信息融合的高精度定位',
          content: '国内首款无人奶茶车，支持扫码下单，多种口味可选。 ',
          content1: '采用6轴全自动机械臂，出杯更高效。',
          content2: '山东省第四届"省长杯"工业设计大赛获奖产品。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2无人奶茶、咖啡车.jpg',
          productList: [
            {
              title: '自主流动售卖',
              picSrc: require('../../assets/images/product-img/自主流动售卖.png'),
            },
            {
              title: '云端管理',
              picSrc: require('../../assets/images/product-img/云端管理.png'),
            },
            {
              title: '现调饮品',
              picSrc: require('../../assets/images/product-img/现调饮品.png'),
            },
            {
              title: '一键回家',
              picSrc: require('../../assets/images/product-img/一键回家.png'),
            },
            {
              title: '无人值守',
              picSrc: require('../../assets/images/product-img/无人值守.png'),
            },
            {
              title: '缺货预警',
              picSrc: require('../../assets/images/product-img/缺货预警.png'),
            },
            {
              title: '云端实时监控',
              picSrc: require('../../assets/images/product-img/云端实时监控.png'),
            },
            {
              title: '自主避让',
              picSrc: require('../../assets/images/product-img/自主避让.png'),
            },
          ],
          paramList: [
            {
              parameterName: '长*宽*高',
              parameterDetail: '4490mm*1800mm*2355mm',
              bgColor: '1',
            },
            {
              parameterName: '整备质量',
              parameterDetail: '950kg',
              bgColor: '2',
            },
            {
              parameterName: '饮品储箱体积',
              parameterDetail: '20L/桶 4桶/车',
              bgColor: '1',
            },
            {
              parameterName: '最大货物装载量',
              parameterDetail: '1000kg',
              bgColor: '2',
            },
            {
              parameterName: '转弯半径',
              parameterDetail: '5.4m',
              bgColor: '1',
            },
            {
              parameterName: '额定功率',
              parameterDetail: '5.0kw',
              bgColor: '2',
            },
          ],
        },
        {
          name: '无人驾驶物流车',
          title: '多源异构信息融合的高精度定位',
          content: '独立式智能快递格，最多可同时配送18件包裹。',
          content1: '上装可拆卸式独立设计，可灵活扩展至厂区物料配送。',
          content2: '解决"最后一公里"的配送难题。',
          imgSrc:
            'https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2无人驾驶物流车.jpg',
          productList: [
            {
              title: '多场景应用',
              picSrc: require('../../assets/images/product-img/多场景应用.png'),
            },
            {
              title: '物流追踪',
              picSrc: require('../../assets/images/product-img/物流追踪.png'),
            },
            {
              title: '精准定位',
              picSrc: require('../../assets/images/product-img/精准定位.png'),
            },
            {
              title: '站点停车',
              picSrc: require('../../assets/images/product-img/站点停车.png'),
            },
            {
              title: '语音播报',
              picSrc: require('../../assets/images/product-img/语音播报.png'),
            },
            {
              title: '智能存取',
              picSrc: require('../../assets/images/product-img/智能存取.png'),
            },
            {
              title: '自主避障',
              picSrc: require('../../assets/images/product-img/自主避障.png'),
            },
            {
              title: '云端实时监控',
              picSrc: require('../../assets/images/product-img/云端实时监控.png'),
            },
          ],
          paramList: [
            {
              parameterName: '长*宽*高',
              parameterDetail: '2120mm*1200mm*1600mm',
              bgColor: '1',
            },
            {
              parameterName: '整备质量',
              parameterDetail: '400kg',
              bgColor: '2',
            },
            {
              parameterName: '货箱体积',
              parameterDetail: '1600L',
              bgColor: '1',
            },
            {
              parameterName: '最高车速',
              parameterDetail: '自动15km/h-遥控5km/h',
              bgColor: '2',
            },
            {
              parameterName: '转弯半径',
              parameterDetail: '2.5m',
              bgColor: '1',
            },
            {
              parameterName: '额定功率',
              parameterDetail: '3.5kw',
              bgColor: '2',
            },
          ],
        },
      ],
    };
  },
  methods: {
    reContent(val) {
      this.tabValue = val;
      // console.log('接收数据', val);
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // if (column.property === 'name') {
      switch (row.bgColor) {
        case '1':
          return {
            height: '68px',
            fontWeight: '400',
            fontSize: '24px',
            color: '#333333',
            lineHeight: '60px !important',
            border: '0px solid #FFFFFF !important',
            fontStyle: 'normal',
            paddingLeft: '128px !important',
          }; //color未生效

        case '2':
          return {
            background: '#F3FCFC',
            height: '68px',
            fontWeight: '400',
            fontSize: '24px',
            border: '0px solid #FFFFFF !important',
            color: '#333333',
            lineHeight: '60px !important',
            fontStyle: 'normal',
            paddingLeft: '128px !important',
          }; //color未生效
        // default:
        //   return {
        //     border: '0px solid #FFFFFF !important',
        //   };
      }
      // }
    },
  },
};
</script>

<style scoped>
/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px !important;
}
/deep/.el-table td.el-table__cell div {
  box-sizing: border-box;
  line-height: 50px !important;
}
/deep/.el-table th.el-table__cell > .cell {
  line-height: 50px !important;
}
</style>

<style lang="scss" scoped>
.product-box1 {
  width: 100%;
  min-width: 880px;
  height: 600px;
  min-height: 500px;
  margin-top: 0px !important;
  // background-color: #545c64;
  background: url('https://apply.backup.realepotech.com/realecg/downGwImage?imgFileName=v2主要产品.jpg')
    center no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  overflow: hidden;

  .product1-title {
    // margin: 0px auto;
    margin-top: 244px;
    // margin-bottom: 5vh;
    font-size: 80px;
    // font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .product-text {
    width: 700px;
    height: 200px;
    // background-color: orange;
    font-size: 16px;
    margin: 0px auto;
    text-align: center;
  }
}
.product-box2 {
  // background-color: #ffff;
  // background-color: #fbfbfb;
  width: 100%;
  min-width: 880px;
  margin: 0px auto !important;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 20px;
  .tab {
    height: 100px;
    .tab-item {
    }
  }
  .tile-bg {
    margin: 12px auto;
    width: 50px;
    height: 6px;
    background: #09ce99;
    border-radius: 4px;
  }
  .box2-title {
    // font-size: 40px;
    margin: 0px auto;
    // text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 50px;
    color: #262626;
    line-height: 70px;
    text-align: center;
    font-style: normal;
  }
  .box2-text {
    width: 800px;
    margin: 20px auto;
    // text-align: center;

    // background-color: beige;
    color: #2f3963;
  }
  .box2-container {
    width: 800px;
    margin: 0 auto;
    padding-top: 20px;
    .box2-img-box {
      width: 100%;
      height: 400px;
      // background-color: aquamarine;
    }
  }
  .car-type {
    width: 1680px;
    height: 635px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    .car-type-content {
      display: flex;
      align-items: center;
      padding: 50px 50px 0 50px;
    }
    .car-type-content1 {
      padding: 0 50px 20px 50px;
      .car-type-content1-row {
        display: flex;
        align-items: center;
        .car-type-content1-img {
          width: 820px;
          height: 520px;
        }
        .pro-item {
          width: 670px;
          height: 210px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }
        .pro-cont-item {
          width: 670px;
          height: 210px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }
        .pro-cont-item1 {
          width: 670px;
          height: 210px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
  .product-type {
    width: 1680px;
    height: 447px;
    background: #ffffff;
    border-radius: 20px;
    margin: 24px auto;
    .car-type-content {
      display: flex;
      align-items: center;
      padding: 50px;
    }
    .product-type-row {
      padding: 30px 0px 0px 0px;
      .product-type-col {
        display: flex;
        justify-content: center;
        .product-type-img {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
  .car-parameters {
    width: 1680px;
    height: 749px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    .car-type-content {
      display: flex;
      align-items: center;
      padding: 50px;
    }
  }
}
.tile-bg {
  margin: 12px auto;
  width: 50px;
  height: 6px;
  background: #09ce99;
  border-radius: 4px;
}

.car-type-title-bg {
  width: 6px;
  height: 28px;
  background: #33d0b5;
  border-radius: 4px;
}
.car-type-title {
  width: 285px;
  height: 49px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 35px;
  color: #262626;
  line-height: 49px;
  text-align: left;
  font-style: normal;
  margin-left: 10px;
}
.product-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 24px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  margin-top: 23px;
}
</style>
